import React, { useEffect, useRef, useState } from "react";
import "./reportForm.scss";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { CaseDetail } from "../../../models/CaseDetail/caseDetail.model";
import { Button, Col, Menu, Popover, Row } from "antd";
import moment from "moment";
import AppLoader from "../AppLoader";
import { Report } from "../../../models/Report/report.model";
import { Form, Formik, FormikValues } from "formik";
import Editor from "../Editor";
import { reportFormValidation } from "./reportFormValidation";
import { ReportTemplate } from "../../../models/ReportTemplate/reportTemplate.model";
import {
  getDropdownOptions,
  IDropdownOptions,
} from "../../utils/dropdownUtils";
import ReportTemplateService from "../../../services/ReportTemplate/reportTemplate.service";
import DropdownField from "../DropdownField";
import ReportService from "../../../services/Report/report.service";
import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import { DownloadFileTypeEnum } from "../../../enums/downloadFileType.enum";
import AdminCaseService from "../../../services/Case/AdminCase/adminCase.service";
import { UserRoleEnum } from "../../../enums/userRole.enum";
import DoctorCaseService from "../../../services/Case/DoctorCase/doctorCase.service";
import { Doctor } from "../../../models/Doctor/doctor.model";
import { Admin } from "../../../models/Admin/admin.model";
import { Case } from "../../../models/Case/case.model";
import { CaseStatusEnum } from "../../../enums/caseStatus.enum";

interface ReportFormProps extends AuthReducerProps {
  caseDetailId: number;
  onPublish: () => void;
  user: Doctor | Admin;
  onClose?: () => void;
  fromViewer?: boolean;
}

function ReportForm({
  caseDetailId,
  userRole,
  onPublish,
  onClose,
  user,
  fromViewer = false,
}: ReportFormProps) {
  const [caseDetail, setCaseDetail] = useState<CaseDetail>();

  const [formValues, setFormValues] = useState(new Report());

  const [reportTemplates, setReportTemplates] = useState<ReportTemplate[]>([]);

  const [reportTemplateOptions, setReportTemplateOptions] = useState<
    IDropdownOptions[]
  >([]);

  const [formLoading, setFormLoading] = useState(false);

  const [publishLoading, setPublishLoading] = useState(false);

  const [pdfLoading, setPdfLoading] = useState(false);

  const [wordLoading, setWordLoading] = useState(false);

  const [caseLoading, setCaseLoading] = useState(false);

  const debounceTimerRef: any = useRef(null);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    clearTimeout(debounceTimerRef.current);
    debounceTimerRef.current = setTimeout(() => {
      handleFetchReportTemplates(searchTerm);
    }, 500);
  }, [searchTerm]);

  const handleDownloadReport = (
    status: boolean | undefined,
    fileType: DownloadFileTypeEnum,
    withLetterHead: boolean
  ) => () => {
    if (caseDetail?.id) {
      setPdfLoading(true);
      if (status) {
        ReportService.downloadReport(
          fileType,
          caseDetail,
          withLetterHead,
          (reportUrl: string) => {
            window.open(reportUrl, "_blank");
          },
          () => {},
          () => {
            setPdfLoading(false);
            setWordLoading(false);
          }
        );
      } else {
        const apiMethod =
          caseDetail?.status === CaseStatusEnum.TO_BE_APPROVED
            ? ReportService.previewApprovalReport
            : ReportService.previewReport;
        apiMethod(
          caseDetail?.id,
          (data: any) => {
            const url = window.URL.createObjectURL(
              new Blob([data], { type: "application/pdf" })
            );
            const pdfWindow = window.open();
            if (pdfWindow) {
              pdfWindow.location.href = url;
              pdfWindow.document.title = "report";
            }
          },
          () => {},
          () => {
            setPdfLoading(false);
            setWordLoading(false);
          }
        );
      }
    }
  };

  const handlePublishReport = (values: FormikValues) => () => {
    const report = Object.assign(new Report(), values);
    if (report && report.id) {
      setPublishLoading(true);
      ReportService.publishReport(
        report,
        (report: Report) => {
          setFormValues(report);
          onPublish && onPublish();
        },
        () => {},
        () => {
          setPublishLoading(false);
        }
      );
    }
  };

  const handleApproveAndPublishReport = (values: FormikValues) => () => {
    const report = Object.assign(new Report(), values);
    if (report && report.id) {
      setPublishLoading(true);
      ReportService.approveAndPublishReport(
        report,
        (report: Report) => {
          setFormValues(report);
          onPublish && onPublish();
        },
        () => {},
        () => {
          setPublishLoading(false);
        }
      );
    }
  };

  const handleSendForApproval = (values: FormikValues) => () => {
    const report = Object.assign(new Report(), values);
    if (report && report.id) {
      setPublishLoading(true);
      ReportService.sendForApproval(
        caseDetailId,
        () => {
          onPublish && onPublish();
          onClose && onClose();
        },
        () => {},
        () => {
          setPublishLoading(false);
        }
      );
    }
  };

  const handleSubmit = (values: Report) => {
    const report = Object.assign(new Report(), values);
    report.caseDetailId = caseDetail?.id;
    setFormLoading(true);
    if (report.id) {
      ReportService.updateReport(
        report,
        () => {
          setFormValues(report);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    } else {
      ReportService.createReport(
        report,
        (report: Report) => {
          setFormValues(report);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  const caseDetails = [
    { label: "Patient Name", value: caseDetail?.patientName },
    { label: "Patient ID", value: caseDetail?.patientId },
    {
      label: "Age / Gender",
      value: `${caseDetail?.age} / ${caseDetail?.gender}`,
    },
    {
      label: "Uploaded Date",
      value: moment(caseDetail?.createdAt).format("MMM DD YYYY"),
    },
    {
      label: "Scan Type",
      value: caseDetail?.scanTypeName,
    },
    {
      label: "Body Parts",
      value: caseDetail?.caseBodyParts
        ?.map((caseBodyPart) => caseBodyPart.bodyPartName)
        .join(", "),
    },
    {
      label: "Report Published",
      value: formValues?.status ? "Yes" : "No",
    },
    {
      label: "",
      value: formValues?.id ? (
        formValues?.status ? (
          <Popover
            destroyTooltipOnHide
            overlayClassName="admin-cases__assignment-popover"
            placement="bottomLeft"
            content={
              <div>
                <Menu mode="vertical" className="border-none">
                  <Menu.Item
                    className="navbar-item m-0"
                    key="with_letterhead"
                    onClick={handleDownloadReport(
                      formValues?.status,
                      DownloadFileTypeEnum.PDF,
                      true
                    )}
                  >
                    Download with Letterhead
                  </Menu.Item>
                  <Menu.Item
                    className="navbar-item m-0"
                    key="without_letterhead"
                    onClick={handleDownloadReport(
                      formValues?.status,
                      DownloadFileTypeEnum.PDF,
                      false
                    )}
                  >
                    Download without Letterhead
                  </Menu.Item>
                  <Menu.Item
                    className="navbar-item m-0"
                    key="as_letterhead"
                    onClick={handleDownloadReport(
                      formValues?.status,
                      DownloadFileTypeEnum.WORD,
                      false
                    )}
                  >
                    Download as Word
                  </Menu.Item>
                </Menu>
              </div>
            }
            title="Download"
            trigger="click"
          >
            <Button type="primary" loading={pdfLoading}>
              <React.Fragment>
                <DownloadOutlined className="mr-1" />
                Download
              </React.Fragment>
            </Button>
          </Popover>
        ) : (
          <Button
            type="primary"
            onClick={handleDownloadReport(
              formValues?.status,
              DownloadFileTypeEnum.PDF,
              false
            )}
            loading={pdfLoading}
          >
            <React.Fragment>
              <EyeOutlined className="mr-1" />
              Preview PDF
            </React.Fragment>
          </Button>
        )
      ) : (
        ""
      ),
    },
  ];

  const handleFetchReportTemplates = (searchText: string) => {
    ReportTemplateService.fetchReportTemplates(
      searchText,
      (reportTemplates: ReportTemplate[]) => {
        setReportTemplates(reportTemplates);
        setReportTemplateOptions(
          getDropdownOptions(reportTemplates, "name", "id")
        );
      },
      () => {},
      () => {}
    );
  };

  useEffect(() => {
    handleFetchReportTemplates("");
  }, []);

  useEffect(() => {
    if (caseDetail?.id) {
      if (caseDetail?.status === CaseStatusEnum.TO_BE_APPROVED) {
        ReportService.showAdminReport(
          caseDetailId,
          (report: Report) => {
            setFormValues(report);
          },
          () => {
            setFormValues(new Report());
          },
          () => {}
        );
      } else {
        ReportService.showReport(
          caseDetailId,
          (report: Report) => {
            setFormValues(report);
          },
          () => {
            setFormValues(new Report());
          },
          () => {}
        );
      }
    }
  }, [caseDetail]);

  useEffect(() => {
    if (caseDetailId) {
      const showMethod =
        userRole === UserRoleEnum.ADMIN
          ? AdminCaseService.showAdminCase
          : DoctorCaseService.showDoctorCase;
      showMethod(
        caseDetailId,
        (caseDetail: CaseDetail) => {
          setCaseDetail(caseDetail);
        },
        () => {},
        () => {}
      );
    }
  }, [caseDetailId]);

  return (
    <div className="report-form">
      {caseLoading ? (
        <AppLoader loading={caseLoading} />
      ) : (
        <div>
          <Row gutter={[20, 20]}>
            {caseDetails?.map((caseDetail, i) => (
              <Col span={8} key={i}>
                <div className="report-form__label">{caseDetail?.label}</div>
                <div className="report-form__value">{caseDetail?.value}</div>
              </Col>
            ))}
          </Row>
          {!formValues?.status && (
            <Formik
              initialValues={formValues}
              onSubmit={handleSubmit}
              enableReinitialize
              validationSchema={reportFormValidation}
            >
              {({ values, errors, isValid, setFieldValue }) => {
                return (
                  <Form>
                    <Row gutter={[0, 20]}>
                      <Col span={fromViewer ? 24 : 12}>
                        <DropdownField
                          allowClear
                          placeHolder="Select Template"
                          showSearch
                          title="Report Template"
                          name="reportTemplateId"
                          onSearch={(value) => {
                            setSearchTerm(value);
                          }}
                          onClear={() => {
                            setSearchTerm("");
                          }}
                          onChange={(value) => {
                            setFieldValue("reportTemplateId", value);
                            const template = reportTemplates.find(
                              (template) => template.id === value
                            );
                            if (template) {
                              setFieldValue(
                                "content",
                                values.content
                                  ? values?.content + "<br/>" + template.content
                                  : template.content
                              );
                            }
                          }}
                          options={reportTemplateOptions}
                        />
                      </Col>
                      <Col span={24}>
                        <Editor
                          error={errors.content as string}
                          value={values.content || ""}
                          onChangeHandler={(content) => {
                            setFieldValue("content", content);
                          }}
                        />
                      </Col>
                    </Row>
                    <div className="report-form__submit-wrapper text-right">
                      {values?.id &&
                        !values.status &&
                        (user?.approvalRequired ? (
                          <Button
                            className="mr-2"
                            type="primary"
                            htmlType="button"
                            loading={publishLoading}
                            disabled={publishLoading}
                            onClick={handleSendForApproval(values)}
                          >
                            Send for Approval
                          </Button>
                        ) : caseDetail?.status ===
                            CaseStatusEnum.TO_BE_APPROVED &&
                          userRole === UserRoleEnum.ADMIN ? (
                          <Button
                            className="mr-2"
                            type="primary"
                            htmlType="button"
                            loading={publishLoading}
                            disabled={publishLoading}
                            onClick={handleApproveAndPublishReport(values)}
                          >
                            Approve and Publish Report
                          </Button>
                        ) : (
                          <Button
                            className="mr-2"
                            type="primary"
                            htmlType="button"
                            loading={publishLoading}
                            disabled={publishLoading}
                            onClick={handlePublishReport(values)}
                          >
                            Save & Publish Report
                          </Button>
                        ))}
                      {!values.status && (
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={formLoading}
                          disabled={!isValid || formLoading}
                        >
                          Save Report
                        </Button>
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      )}
    </div>
  );
}

export default AuthContainer(ReportForm);
