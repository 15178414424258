import { CreateReducer } from "../../shared/utils/createReducer";
import {
  SetPendingAction,
  SetPendingCaseIdsAction,
  SetUploadCompleteAction,
  SetUploadInProgressAction,
  SetUploadStartAction,
} from "../actions/caseActions";
import {
  SET_PENDING,
  SET_PENDING_CASE_IDS,
  SET_UPLOAD_COMPLETE,
  SET_UPLOAD_IN_PROGRESS,
  SET_UPLOAD_START,
} from "../definitions/caseConstants";

export interface CaseState {
  pendingCaseIds: number[];
  completedCaseIds: number[];
  uploadStartedCaseIds: number[];
}

export interface CaseReducerProps extends CaseState {
  setPendingCaseIds: (pendingCaseIds: number[]) => SetPendingCaseIdsAction;
  setPending: (caseDetailId: number) => SetPendingAction;
  setUploadStart: (caseDetailId: number) => SetUploadStartAction;
  setUploadInProgress: (caseDetailId: number) => SetUploadInProgressAction;
  setUploadComplete: (caseDetailId: number) => SetUploadCompleteAction;
}

const initState: CaseState = {
  pendingCaseIds: [],
  uploadStartedCaseIds: [],
  completedCaseIds: [],
};

const caseReducer = CreateReducer(initState, {
  [SET_PENDING_CASE_IDS](
    state: CaseState,
    action: SetPendingCaseIdsAction
  ): CaseState {
    const { pendingCaseIds } = action?.payload;
    return {
      ...state,
      pendingCaseIds,
    };
  },
  [SET_PENDING](state: CaseState, action: SetPendingAction): CaseState {
    const { caseDetailId } = action?.payload;
    const { pendingCaseIds } = state;
    const caseIds = [...pendingCaseIds];
    if (!pendingCaseIds.includes(caseDetailId)) {
      caseIds.push(caseDetailId);
    }
    return {
      ...state,
      pendingCaseIds: caseIds,
    };
  },
  [SET_UPLOAD_START](
    state: CaseState,
    action: SetUploadStartAction
  ): CaseState {
    const { caseDetailId } = action?.payload;
    const { pendingCaseIds, uploadStartedCaseIds } = state;
    const caseIds = [...uploadStartedCaseIds];
    const pendingIds = [...pendingCaseIds];
    const caseIndex = pendingIds.findIndex((caseId) => caseId === caseDetailId);
    if (caseIndex >= 0) {
      pendingIds.splice(caseIndex, 1);
    }
    if (!uploadStartedCaseIds.includes(caseDetailId)) {
      caseIds.push(caseDetailId);
    }
    return {
      ...state,
      uploadStartedCaseIds: caseIds,
      pendingCaseIds: pendingIds,
    };
  },
  [SET_UPLOAD_IN_PROGRESS](
    state: CaseState,
    action: SetUploadStartAction
  ): CaseState {
    const { caseDetailId } = action?.payload;
    const { uploadStartedCaseIds } = state;
    const caseIds = [...uploadStartedCaseIds];
    if (!uploadStartedCaseIds.includes(caseDetailId)) {
      caseIds.push(caseDetailId);
    }
    return {
      ...state,
      uploadStartedCaseIds: caseIds,
    };
  },
  [SET_UPLOAD_COMPLETE](
    state: CaseState,
    action: SetUploadStartAction
  ): CaseState {
    const { caseDetailId } = action?.payload;
    const { uploadStartedCaseIds, completedCaseIds } = state;
    const caseIds = [...uploadStartedCaseIds];
    const completedIds = [...completedCaseIds];
    const caseIndex = caseIds.findIndex((caseId) => caseId === caseDetailId);
    if (caseIndex >= 0) {
      completedIds.push(caseDetailId);
      caseIds.splice(caseIndex, 1);
    }
    return {
      ...state,
      uploadStartedCaseIds: caseIds,
      completedCaseIds: completedIds,
    };
  },
});

export default caseReducer;
