import { serializable, alias, primitive } from "serializr";
import { CaseStatusEnum } from "../../enums/caseStatus.enum";

export class CaseFilterParams {
  @serializable(alias("scan_center_id", primitive()))
  scanCenterId?: number;

  @serializable(alias("scan_center_branch_id", primitive()))
  scanCenterBranchId?: number;

  @serializable(alias("from_date", primitive()))
  fromDate?: string;

  @serializable(alias("to_date", primitive()))
  toDate?: string;

  @serializable(alias("doctor_id", primitive()))
  doctorId?: number;

  @serializable(alias("scan_type_id", primitive()))
  scanTypeId?: number;

  @serializable(alias("body_part_id", primitive()))
  bodyPartId?: number;

  @serializable(alias("status", primitive()))
  status?: string;
}
